<template>
  <div id="market-wrapper" :class="`${$route.path === '/market/list' ? 'market-container' : ''}`">
    <banner-market></banner-market>
    <!-- 메뉴 -->
    <div v-if="fixedState" style="height:70px"></div>
    <div :style="fixedStyle">
      <div class="flex-center cat-wrapper"
           v-if="filtered.selectedMenu" ref="tabMenu">
        <div v-for="menu in menus"
             :key="'menu-'+menu.id"
             class="cat-st subtitle5 unselect"
             :class="{'primary':filtered.selectedMenu.id===menu.id}"
             @click="clickMenu(menu)">
          {{ menu.label }}
          <div :class="{'cat-selected':filtered.selectedMenu.id===menu.id}"></div>
        </div>
      </div>
    </div>
    <div class="container-wrapper">
      <div class="container">
        <!-- 전체가 아닐경우 -->
        <div v-if="filtered.selectedMenu && filtered.selectedMenu.label!=='전체'">
          <template v-if="filtered.selectedMenu.label === '플랫폼 팩'">
            <div class="pc">
              <div class="h5 padding-top-60 padding-bottom-16">자동설치 플랫폼 팩
                <span class="h5 primary margin-left-4">{{ packLength }}</span>
              </div>
              <div class="body2 sub3 margin-bottom-32">기능 추가를 원할 시, 즉시 설치 됩니다</div>
            </div>
            <div class="mobile">
              <div class="h6 padding-top-60 padding-bottom-8">자동설치 플랫폼 팩
                <span class="h6 primary margin-left-4">{{ packLength }}</span>
              </div>
              <div class="body4 sub3 margin-bottom-16">기능 추가를 원할 시, 즉시 설치 됩니다</div>
            </div>
          </template>
          <template v-else-if="filtered.selectedMenu.label === '플러그인'">
            <div class="pc">
              <div class="h5 padding-top-60 padding-bottom-16">자동설치 플러그인
                <span class="h5 primary margin-left-4">{{ pluginLength }}</span>
              </div>
              <div class="body2 sub3 margin-bottom-32">기능 추가를 원할 시, 즉시 설치 됩니다</div>
            </div>
            <div class="mobile">
              <div class="h6 padding-top-60 padding-bottom-8">자동설치 플러그인
                <span class="h6 primary margin-left-4">{{ pluginLength }}</span>
              </div>
              <div class="body4 sub3 margin-bottom-16">기능 추가를 원할 시, 즉시 설치 됩니다</div>
            </div>
          </template>

          <div v-else class="h5 padding-top-60 padding-bottom-28">{{ filtered.selectedMenu.label }}
            <span class="h5 primary margin-left-4">{{ list.length }}</span></div>

          <list-product-app-market :key="'list-market-'+filtered.selectedMenu.id" :menu="filtered.selectedMenu"
                                   :shadow="true" :products="list"
                                   :single="['플랫폼 팩', '플러그인'].indexOf(filtered.selectedMenu.label)>-1"></list-product-app-market>
        </div>
        <!-- 전체일 경우 -->
        <div v-else-if="menus.length>0">
          <template v-for="(item, item_idx) in menus.slice(1,menus.length)">
            <div class="product-item-wrapper" :key="`menu-${item_idx}`">
              <div class="pc">
                <div class="flex-between padding-top-60 padding-bottom-28">
                  <div class="unselect" @click="clickMenu(item)">
                    <span class="h5">{{ item.label }}</span>
                    <span class="h5 primary margin-left-4">{{ item.count }}</span>
                  </div>
                  <div class="body2-medium primary unselect" @click="clickMenu(item)">더보기</div>
                </div>
              </div>
              <div class="mobile">
                <div class="flex-between padding-top-40">
                  <div class="unselect" @click="clickMenu(item)">
                    <span class="h6">{{ item.label }}</span>
                    <span class="h6 primary margin-left-4">{{ item.count }}</span>
                  </div>
                  <div class="body4-medium primary unselect" @click="clickMenu(item)">더보기</div>
                </div>
              </div>
              <ListProductAppMarket :menu="item" :shadow="true" :products="item.products"></ListProductAppMarket>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import ListMixin from "../../mixins/ListMixin";
  import ListProductAppMarket from "../component/ListProductAppMarket";
  import BannerMarket from "../component/ListProductAppMarket.vue";
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";
  export default {
    components: {
      BannerMarket,
      ListProductAppMarket
    },
    name: "Market",
    mixins: [
      ListMixin,
      ServiceAdminAPIMixin
    ],
    watch: {
      '$route': {
        handler(n) {
          this.vMarket = n.path.indexOf('/detail') === -1;
        },
        deep: true
      }
    },
    data() {
      return {
        controller: undefined,
        vMarket: true,
        list: [],
        filtered: {
          selectedMenu: undefined
        },
        menus: [],
        recent:false,
        fields: 'id,name,simple_desc,img,price,partner.img,partner.name,category1,category2,preview_url,tags,params',
        orders: [],
        pluginLength: 0,
        packLength: 0,
        fixedState: false
      }
    },
    created() {
      this.pageLoadType = 'infinite';
      this.init();

    },
    activated() {
      this.$nextTick(()=>{
        if (this.$route.query.id) {
          setTimeout(() => {
            let menu = this.menus.filter(item => { return item.id === Number(this.$route.query.id) })[0]
            this.clickMenu(menu)
          }, 500)
        }
        this.controller = new AbortController();
        const { signal } = this.controller;
        window.addEventListener('scroll', this.detectClientHeight, { signal });
      });
    },
    deactivated() {
      this.controller.abort();
    },
    computed: {
      fixedStyle() {
        let deco = {
          borderBottom: '1px solid #e3e3e3'
        };
        if (this.fixedState) {
          deco = Object.assign(deco, {
            width: '100%',
            backgroundColor: '#fff',
            position: 'fixed',
            top: '70px',
            left: '0',
            zIndex: 9
          });
        }
        return deco;
      },
    },
    methods: {
      // 탭 선택
      clickMenu(menu) {
        this.setGa(`테마 카테고리(${menu.label})`, '클릭', menu.label);
        this.getMenu(menu);
        window.scrollTo(0, 0);
      },
      getMenu(menu) {
        this.current_page = 1;
        this.filtered.page_num = 1;
        this.filtered.selectedMenu = menu;
        if(this.filtered.selectedMenu.id===0) {
          this.getProductsFilter();
        } else {
          this.getProducts();
        }
      },
      async init() {
        this.list = [];
        this.menus = [];
        if(this.$route.path !== '/market/list') {
          await this.getOrders();
        }
        let url = 'public/category?parent_id=46'
        this.$axios.get(url).then(res => {
          if (res.status === 200) {
            let menus = [{
                desc: "",
                img: "",
                id: 0,
                label: "전체"
              }]
            ;
            this.menus = menus.concat(res.data).map(i => {
              i.products = [];
              return i;
            });
            this.menus[0].label = '전체';

            if(this.$route.query.tab) {
              let menu = this.findItem(this.menus, 'id', parseInt(this.$route.query.tab));
              if(menu) {
                this.clickMenu(menu);
                this.filtered.selectedMenu = menu;
              }
            } else {
              this.filtered.selectedMenu = this.menus[0];
              this.getProductsFilter();
            }
          }
        })
      },
      async getOrders() {
        let res = await this.request.serviceAdmin.get(`launchpack/v1/service/${this.userService.id}/app_order`);
        this.orders = res.data;
      },
      getProductsFilter() {
        this.menus.filter(menu => { return menu.id > 0 }).forEach(menu => {
          let num = menu.name === '플랫폼 팩' ? 4 : 3;
          this.$axios.get(`user/0/mapping/product?fields=${this.fields}&page_num=1&page_length=100&category=${menu.id}&ordering=priority`).then(async res =>{

            // 결제방식 사용 안하는 팩만 보유한 경우, 결제있음 상태의 상품 제외하기
            if(this.userService && this.userService.packages.every(i => ['call'].indexOf(i)>-1)) {
              res.data.data = res.data.data.filter(i => i.category2_id !== 48 ||
                (i.category2_id === 48 && !i.params.find(n => n.name === '결제방식').value.some(v => v.name === '결제있음')));
            }

            menu.products = this.setStatus(res.data.data).slice(0, num);
            menu.count = res.data.count;
          });
        });
      },
      getProducts() {
        this.list = [];
        let category = '';
        let cat = 0;
        if(this.filtered.selectedMenu && this.filtered.selectedMenu.id > 0) {
          cat = this.filtered.selectedMenu.id;
          category = `&category=${cat}`;
        }
        this.currentPage = 1;
        this.url = `user/0/mapping/product?${category}&fields=${this.fields}&page_num=1&page_length=100&ordering=priority`
        this.$axios.get(this.url).then(async res => {
          if (res.status === 200) {
            this.totalPages = res.data.total_page;
            this.totalCount = res.data.count;
            this.currentPage = 2;

            // 결제방식 사용 안하는 팩만 보유한 경우, 결제있음 상태의 상품 제외하기
            if(this.userService && this.userService.packages.every(i => ['call'].indexOf(i)>-1) && cat === 48) {
              res.data.data = res.data.data.filter(i => !i.params.find(n => n.name === '결제방식').value.some(v => v.name === '결제있음'));
            }


            this.list = this.setStatus(res.data.data);
            if(this.filtered.selectedMenu) {
              if(this.filtered.selectedMenu.label=== '플랫폼 팩') {
                this.packLength = this.list.filter(i=>{ return i.grade === 0 }).length;
              } else if(this.filtered.selectedMenu.label=== '플러그인') {
                this.pluginLength = this.list.filter(i=>{ return i.grade === 0 }).length;
              }
            }
          }
        })
      },
      setStatus(arr) {
        for (const data of arr) {
          let app = this.orders.filter(item => {
            return item.products.length > 0 && item.products[0].id === data.id && item.status <= 2
          });
          data.status = app.length>0 ? app[0].status : 0;

          let grade = data.params.filter(i => { return i.name === '플러그인 등급' });
          if(grade.length > 0 && grade[0].value.length > 0 && grade[0].value[0].name !== '기본') {
            data.grade = 1;
          } else {
            data.grade = 0;
          }
        }
        arr.sort((a,b)=> {
          return a.status - b.status;
        }).sort((a,b) => {
          return a.grade - b.grade;
        });
        return arr;
      },
      detectClientHeight() {
        this.$nextTick(() => {
          if (this.$refs.tabMenu) {
            this.fixedState = document.getElementById('market-wrapper').getBoundingClientRect().y < 72;
          }
        })
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  #market-wrapper
    width 1200px
    margin 70px auto 0 auto

  .market-container
    margin-top 70px

  .cat-selected
    position absolute
    left 0
    bottom 0
    width 100%
    background-color $primary
    height 3px

  .cat-wrapper
    display grid
    grid-template-columns repeat(4, 1fr)
    max-width 420px
    margin auto

  .cat-st
    flex 0 0 auto
    position relative
    padding 20px 0
    text-align center

  .product-item-wrapper
    border-bottom 1px solid $gray1
    padding-bottom 56px

  .product-item-wrapper:last-child
    border-bottom 0

  .container-wrapper
    background-color $gray3
    min-height 100vh
    padding-bottom 40px

</style>
