import axios from 'axios'

const adminAxios = axios.create({
  headers: {
    common: {}
  }
});

export default {
  data() {
    return {
      request: {
        serviceAdmin: adminAxios
      }
    };
  },
  created() {
    this.setAuth();
  },
  methods: {
    setAuth() {
      this.request.serviceAdmin.defaults.baseURL = `${this.backendUrl}/serviceadmin/${this.$store.getters.admin.id}` ;
      this.request.serviceAdmin.defaults.headers.common.Authorization = `Token ${this.$store.getters.admin.token}`;
    }
  }
}
